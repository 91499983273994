.puzzle {
  animation: slideInFromLeft 3s ease-out;
}

.shadow {
  -webkit-filter: drop-shadow(2px 2px 2px #222);
  filter: drop-shadow(2px 2px 2px #222);
}

.timer-preview-container {
  animation: fadeIn 2s ease-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-300px); /* Start from the left (negative value) */
  }
  100% {
    transform: translateX(0); /* Slide to the original position (0) */
  }
}

@media (max-width: 840px) {
  #puzzle {
    margin-bottom: 180px;
    animation: slidedown 2s ease-out;
  }

  @keyframes slidedown {
    0% {
      transform: translateY(-300px); /* Start from the left (negative value) */
    }
    100% {
      transform: translateY(0); /* Slide to the original position (0) */
    }
  }
}
